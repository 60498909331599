<template>
  <div class="app" :class="[{ 'app-bg-dark': isDashboardLayout, 'big-container': isBigContainer }, currentEnv]">
    <sticky-bar v-if="showStickyBars" />
    <app-header v-if="!isDashboardLayout" />
    <dashboard-header-filedirect v-if="isFileDirectDashboard" />
    <div class="app-content">
      <b-container :fluid="isFullWidthLayout">
        <router-view />
      </b-container>
    </div>
    <coupon-modal :is-open="couponModalOpen" :on-close="modalOnClose" :discount="discount" :on-success="modalOnClose">
      <h4>{{ discountTitle }}</h4>
    </coupon-modal>
    <session-modal />
    <app-footer v-if="!isDashboardLayout" />
    <Teleport to="body">
      <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <b-toast id="reload-toast" v-model="reloadToast" solid @hidden="noUpdate">
          <template #title>
            <div class="ms-auto me-auto">New website version available!</div>
          </template>
          <div>
            <div class="reload-toast-message">
              <div>And will be updated in {{ remainingTime }} seconds automatically.</div>
              <div>Or click "Refresh" to update now</div>
            </div>
            <div class="reload-toast-button">
              <b-button variant="primary" size="sm" @click="refreshApp">Refresh</b-button>
            </div>
          </div>
        </b-toast>
      </div>
    </Teleport>

    <sticky-bar-flash-deal />
  </div>
</template>

<script lang="ts">
import "@fontsource/mulish/latin.css";
import "@fontsource/metropolis/latin.css";
import "@fontsource/montserrat/latin.css";
import "@fontsource/inter/latin.css";
import "@/styles/fonts.scss";
import "./styles.scss";

import * as Sentry from "@sentry/vue";
import axios from "axios";
import { defineComponent } from "vue";
import type { NavigationGuardNext, RouteLocationNormalizedLoadedGeneric } from "vue-router";

import { startOAuthPath } from "@/api/oauth";
import { GetClientIp, setTrafficSource } from "@/api/user";
import CouponModal from "@/components/coupon-modal/coupon-modal.vue";
import AppFooter from "@/components/footer/index.vue";
import AppHeader from "@/components/header/index.vue";
import SessionModal from "@/components/session-modal/index.vue";
import StickyBar from "@/components/sticky-bar/sb.vue";
import StickyBarFlashDeal from "@/components/sticky-bar/sb-flash-deal.vue";
import { useAppEngine } from "@/composables/use-app-engine";
import {
  cookieNames,
  MONTH_IN_MS,
  THREE_DAYS_IN_SECONDS,
  THREE_MINUTES_IN_MS,
  THREE_MONTHS_IN_MS,
  TWO_MONTHS_IN_MS
} from "@/config/constants";
import DashboardHeaderFiledirect from "@/pages/dashboard/header-filedirect/index.vue";
import { tricklingProgress } from "@/plugins/trickling";
import type { UserTrafficSourceRequest } from "@/services/api";
import { CommonAccessScopes, CommonProductFocus } from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useFormsStore } from "@/store/forms.store";
import { usePostHogStore } from "@/store/posthog.store";
import { useAppStateStore } from "@/store/state.store";
import type { ExtJwtPayload } from "@/store/types";
import { getCookie, removeCookie, setCookie } from "@/util/cookies";
import type { CatchError } from "@/util/error";
import { handleError } from "@/util/error";
import { getLogger } from "@/util/logger";
import { getQueryParameter } from "@/util/query-string";
import { addToDataLayer } from "@/util/tracking";

const logger = getLogger("App");

export default defineComponent({
  name: "AppRoot",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "dashboard-header-filedirect": DashboardHeaderFiledirect,
    "coupon-modal": CouponModal,
    "session-modal": SessionModal,
    "sticky-bar": StickyBar,
    "sticky-bar-flash-deal": StickyBarFlashDeal
  },
  setup() {
    const authStore = useAuthStore();
    const appStateStore = useAppStateStore();
    const postHogStore = usePostHogStore();
    const formsStore = useFormsStore();
    const cartStore = useCartStore();
    const { currentEngine, isFileDirectEngine } = useAppEngine();

    return { authStore, formsStore, appStateStore, currentEngine, isFileDirectEngine, postHogStore, cartStore };
  },
  data() {
    return {
      toastInstance: null,
      couponModalOpen: false,
      discountTitle: `Your discount is confirmed!`,
      discount: "",
      currentVersion: "",
      refreshing: false,
      updateExists: false,
      remainingTime: 30,
      reloadToast: false as number | boolean,
      checkVersionInterval: null as number | null,
      tickInterval: null as number | null,
      isLoggedIn: false,
      isFirstNavigation: true
    };
  },
  computed: {
    currentEnv(): string {
      return import.meta.env.MODE !== "production" ? "app-dev" : "app-prod";
    },
    showStickyBars(): boolean {
      return this.authStore.isAuthorized && this.$route.name !== "landing";
    },
    isDashboardLayout(): boolean {
      return this.$route.meta?.layout === "dashboard";
    },
    isFileDirectDashboard(): boolean {
      return this.isDashboardLayout && this.isFileDirectEngine;
    },
    isFullWidthLayout(): boolean {
      const layout = this.$route.meta?.layout;

      switch (layout) {
        case "full":
          return true;
        case "dashboard":
          if (this.isFileDirectEngine) return false;

          return true;
        default:
          return false;
      }
    },
    isBigContainer(): boolean {
      return this.$route.meta?.container === "big";
    },
    isFormPath(): boolean {
      return this.$route.path.includes("extension/");
    }
  },
  watch: {
    "authStore.isAuthorized"(state: boolean): void {
      if (state) this.submitUtmInfo();
    }
  },
  updated(): void {
    if (this.authStore.tokenData) {
      const tokenData: ExtJwtPayload | null = this.authStore.tokenData;
      const scopes = tokenData?.scopes ?? [];

      if (scopes.includes("client")) {
        this.isLoggedIn = true;
        this.checkCoupon();
      }
    }
  },
  async mounted() {
    tricklingProgress.done();

    await this.authStore.updateDeviceId();

    if (import.meta.env.MODE === "production") {
      this.checkVersion();
      this.checkVersionInterval = window.setInterval(this.checkVersion, THREE_MINUTES_IN_MS);
    }

    if (!window.location.pathname.includes("personal")) {
      window.localStorage.removeItem("prev-funnel-page");
    }

    this.initGoogleOneTap();

    this.cartStore.moveGuestCartToUser();
  },
  beforeUnmount() {
    if (this.checkVersionInterval) {
      window.clearInterval(this.checkVersionInterval);
      this.checkVersionInterval = null;
    }

    if (this.tickInterval) {
      clearTimeout(this.tickInterval);
      this.tickInterval = null;
    }
  },
  created() {
    console.log("_ga: ", getCookie("_ga"));
    // Adding recaptcha earlier in the process as suggested by FYT and google
    // https://developers.google.com/recaptcha/docs/v3
    const recaptchaUrl = import.meta.env.VITE_RECAPTCHA_URL;
    if (!document.querySelector(`[src="${recaptchaUrl}"]`)) {
      console.log("Mounting recaptcha: ", recaptchaUrl);
      const script = document.createElement("script");
      script.setAttribute("src", recaptchaUrl);
      document.head.appendChild(script);
    }

    document.body.classList.add(`theme-${this.currentEngine}`);

    if (!getCookie(cookieNames.source)) {
      const s = getQueryParameter("source");
      if (s && s !== "" && s !== undefined && s !== "undefined") {
        setCookie(cookieNames.source, s as string, TWO_MONTHS_IN_MS);
      }
    }

    if (!getCookie(cookieNames.userIp)) {
      GetClientIp().then((ip: string) => {
        setCookie(cookieNames.userIp, ip, THREE_MONTHS_IN_MS);
      });
    }

    const params = new URLSearchParams(window.location.search);
    const qsCoupon = params.get("cpn");

    if (qsCoupon) {
      setCookie(cookieNames.coupon, qsCoupon, TWO_MONTHS_IN_MS);
    }

    const coupon = getCookie<string[]>(cookieNames.coupon);

    if (this.$route.query.cpn) {
      const query = { ...this.$route.query, cpn: undefined };
      this.$router.replace({ query });
    }

    if (coupon) {
      logger.info("Coupon found, saving them for later processing");
      if (this.isLoggedIn) {
        logger.info("User is already logged in, can process right now");
        this.checkCoupon();
      }
    }

    this.storeUtmInCookies();

    this.$router.beforeResolve((_to, from: RouteLocationNormalizedLoadedGeneric, next: NavigationGuardNext) => {
      if (from.name) this.postHogStore.pageLeaveEvent(from.path);

      tricklingProgress.start();

      next();
    });

    this.$router.afterEach((to) => {
      tricklingProgress.done();

      this.postHogStore.pageViewEvent(to.path);

      if (!this.isFirstNavigation) {
        addToDataLayer({ event: "pageview", page: to.path, funnel: this.authStore.funnel });
      } else {
        this.isFirstNavigation = false;
      }

      addToDataLayer({ event: "optimize.activate" });

      try {
        if (this.authStore.isAuthorized) {
          window.hj("identify", this.authStore.userData.id, {
            email: this.authStore.userData.email,
            username: this.authStore.userData.username
          });
        }
      } catch (e: CatchError) {
        handleError(e);
      }

      if (getCookie(cookieNames.userIp)) {
        addToDataLayer({
          event: "userIp",
          ipAddress: getCookie(cookieNames.userIp)
        });
      }

      try {
        if (this.authStore.isAuthorized) {
          window.hj("identify", this.authStore.userData.id, {
            email: this.authStore.userData.email,
            username: this.authStore.userData.username
          });
        }
      } catch (e: CatchError) {
        handleError(e);
      }
    });
  },
  methods: {
    modalOnClose(): void {
      this.couponModalOpen = false;
    },
    async checkCoupon() {
      const coupon = getCookie<string>(cookieNames.coupon);

      if (!coupon) {
        return;
      }

      logger.info("Applying coupon", coupon);

      try {
        const response = await this.formsStore.applyCoupon(coupon);

        if (response.discount && response.discount > 0) {
          this.discount = response.discount.toString();
          this.discountTitle = `Your ${this.discount}% discount is confirmed!`;
          this.couponModalOpen = true;
        }

        if (response.fixed_price && response.fixed_price > 0) {
          this.discount = response.fixed_price.toString();
          this.discountTitle = `Your form fixed price is set to ${this.discount}% !`;
          this.couponModalOpen = true;
        }
      } catch (e) {
        logger.error("Failed to apply coupon: " + coupon, e);
      } finally {
        removeCookie(cookieNames.coupon);
      }
    },
    noUpdate(): void {
      if (this.tickInterval) {
        clearTimeout(this.tickInterval);
        this.tickInterval = null;
      }

      this.remainingTime = 30;
    },
    checkVersion(): void {
      axios
        .get("/version.json")
        .then(({ data }) => {
          const version = data.version;

          if (this.currentVersion === "") {
            this.currentVersion = version;
            return;
          }

          if (this.currentVersion !== version) {
            this.reloadToast = true;
            this.remainingTime = 30;

            this.tickInterval = window.setInterval(() => {
              if (this.remainingTime === 0) {
                this.refreshApp();
              }

              if (this.remainingTime > 0) {
                this.remainingTime -= 1;
              }
            }, 1000);
            this.updateExists = true;
          }
        })
        .catch(() => {
          //
        });
    },
    refreshApp(): void {
      logger.info("Reload Page clicked");

      if (this.checkVersionInterval) {
        clearTimeout(this.checkVersionInterval);
        this.checkVersionInterval = null;
      }

      if (this.tickInterval) {
        clearTimeout(this.tickInterval);
        this.tickInterval = null;
      }

      this.reloadToast = false;

      this.updateExists = false;

      window.location.reload();
    },
    storeUtmInCookies(): void {
      const rtkclkid = getQueryParameter(cookieNames.redtrackClickId);
      if (rtkclkid) {
        setCookie(cookieNames.redtrackClickId, rtkclkid as string, MONTH_IN_MS);
      }

      const refId = getQueryParameter(cookieNames.refId) ?? getQueryParameter(cookieNames.msClickId);
      if (refId) {
        setCookie(cookieNames.refId, refId as string, MONTH_IN_MS);
      }

      const utmSource = getQueryParameter(cookieNames.utmSource);
      if (utmSource) {
        setCookie(cookieNames.utmSource, utmSource as string, MONTH_IN_MS);
      }

      const formShortCode = getQueryParameter(cookieNames.formShortCode);
      if (formShortCode) {
        setCookie(cookieNames.formShortCode, formShortCode as string, MONTH_IN_MS);
      }

      const funnel = getQueryParameter(cookieNames.funnel);
      if (funnel) {
        setCookie(cookieNames.funnel, funnel.toString(), THREE_DAYS_IN_SECONDS);
      }
    },
    async submitUtmInfo(): Promise<void> {
      // return for admin and triage as they have inappropriate tokens
      const accessScopes = this.authStore.userData.access_scopes;
      if (
        accessScopes?.some(
          (scope) => scope === CommonAccessScopes.AccessScopesTriage || scope === CommonAccessScopes.AccessScopesAdmin
        )
      ) {
        return;
      }

      const request: UserTrafficSourceRequest = {};
      const redtrackClickId = getCookie(cookieNames.redtrackClickId);
      const refId = getCookie(cookieNames.refId);
      const utmSource = getCookie(cookieNames.utmSource);
      const lastPassedTrafficSource = getCookie(cookieNames.lastPassedTrafficSource);

      if (redtrackClickId) {
        request.redtrack_click_id = redtrackClickId;
      }

      if (refId) {
        request.ref_id = refId;
      }

      if (utmSource) {
        request.utm_source = utmSource;
      }

      const requestJSON = JSON.stringify(request);

      if (!Object.keys(request).length || requestJSON === lastPassedTrafficSource) {
        return;
      }

      try {
        await setTrafficSource(request);
        setCookie(cookieNames.lastPassedTrafficSource, requestJSON, MONTH_IN_MS);
      } catch (error) {
        Sentry.captureMessage(`Error trying to submit UTM info:${JSON.stringify(error)}`);
      }
    },
    initGoogleOneTap(): void {
      if (this.authStore.isAuthorized || this.isDashboardLayout || this.isFormPath) {
        return;
      }

      const interval = setInterval(() => {
        if (this.authStore.isAuthorized) {
          window.clearInterval(interval);
          return;
        }

        const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
        const isOneTapEnabled = import.meta.env.VITE_GOOGLE_ONE_TAP === "true";

        if (!window.google || !clientId || !isOneTapEnabled) {
          return;
        }

        window.google.accounts.id.initialize({
          client_id: clientId,
          cancel_on_tap_outside: false,
          callback: this.googleOneTapRedirect
        });

        window.google.accounts.id.prompt(true);
        window.clearInterval(interval);
      }, 1000);
    },
    googleOneTapRedirect(): void {
      const isDfy = getCookie(cookieNames.dfyVersion);
      const newProductFocus = isDfy ? CommonProductFocus.ProductFocusDFY : undefined;

      window.location.href = startOAuthPath(
        this.authStore.funnel,
        "google",
        this.authStore.registrationSource,
        this.authStore.deviceId,
        newProductFocus
      );
    }
  }
});
</script>
